import React, { useEffect, useState } from "react";
import { roundedBoxShadowBorderRadius } from "./atoms/RoundedBoxShadowBox";

const addTag = (tag = "") => {
  if (window.history.pushState) {
    window.history.pushState(null, null, tag);
  } else {
    window.location.hash = tag;
  }
};

export const QuestionAnswer = ({ question = "", answer = "", id = "" }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (window.location.hash === `#${id}`) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, [id]);

  useEffect(() => {
    if (showContent) {
      addTag(`#${id}`);
    }
  }, [showContent, id]);

  return (
    <div
      className="box"
      id={id}
      style={{
        borderRadius: roundedBoxShadowBorderRadius,
        boxShadow: "2px 4px 10px #C4C4C4",
      }}
    >
      <div className="content">
        <button
          className="title has-background-white"
          style={{
            cursor: "pointer",
            border: "none",
            textAlign: "left",
          }}
          onClick={() => setShowContent(!showContent)}
        >
          {question}
        </button>
        {showContent && <p style={{ whiteSpace: "pre-line" }}>{answer}</p>}
      </div>
    </div>
  );
};
