import React from "react";
import PropTypes from "prop-types";

import { TextBanner } from "../../components/molecules/TextBanner";
import { QuestionAnswer } from "../../components/QuestionAnswer";
import { LayoutBox } from "../../components/atoms/LayoutBox";

export const FaqsPageTemplate = ({ title, questions = [] }) => {
  return (
    <div>
      <TextBanner title={title} />
      <LayoutBox>
        {questions.map(({ question, answer, id }) => (
          <QuestionAnswer
            key={id}
            question={question}
            answer={answer}
            id={id}
          />
        ))}
      </LayoutBox>
    </div>
  );
};

FaqsPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  questions: PropTypes.array,
};
